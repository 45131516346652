<template>
  <div>test2</div>
</template>

<script>
import axios from "axios";
export default {
  name: "test2",
  mounted() {
    axios.defaults.withCredentials = true;
    axios
      .get("http://vertexmonkey.net/t?t=123456")
      .then(function (res) {
        console.log(res.data);
      })
      .catch(function (err) {
        console.error(err);
      });
  },
};
</script>

<style>
</style>